@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.company {
  display: flex;
  flex: none;
  width: 50%;
  height: 100px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  .companyImgWrapper {
    max-width: 80%;
    width: 100%;
  }
}

@include mq($from: md) {
  .company {
    width: 20%;
  }
}
