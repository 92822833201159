.confirmation {
  .body {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 768px) {
    .body {
      max-width: 60%;
    }
  }
}
