.page-error {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
    padding: 1rem 0;
    margin: 0 auto;
    text-align: center;
  }

  @include mq($from: md) {
    .container {
      max-width: 90%;
    }
  }

  @include mq($from: lg) {
    .container {
      max-width: 60%;
    }
  }
}
