.body p, .body ul, .body ol, .body pre, .body iframe {
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.body .table-plugin img {
    max-width: 120px;
    height: auto;
}

.body img {
    max-width: 100%;
    height: auto;
}

.body table {
    width: 100%;
    margin: 1rem 0;
    border-collapse: collapse;
}

.body table thead th {
    border-bottom: 2px solid #eceeef;
}

.body table th, .body table td {
    padding: .75rem;
    border-top: 1px solid #eceeef;
    text-align: left;
}

.body pre,
.body code {
    font-size: 0.9rem;
    font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    padding: 1px 3px;
    border: #e3edf3 1px solid;
    border-radius: 2px;
    background: #f7fafb;
}

.body pre {
    padding: 10px;
}

.body pre code {
    border: none;
    padding: 0;
    background-color: transparent;
}

.align-center {
    margin: 0 0 2rem;
    text-align: center;
}
