@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.card {
  position: relative;
  margin-bottom: 40px;
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  padding: 0 10px;
}

.cardLight .cardContentWrapper {
  border: 1px solid #7f7f7f;
}

  .cardContentWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: 100%;
    background-color: #fff;

    .cardHighlight & {
      border: 2px solid $brand-tertiary;
      border-radius: 0 0 4px 4px;
      border-top: none;
      background-color: $brand-secondary;
    }
  }

  .cardHeader {
    margin-top: 20px; /* Space to fill up highlighed card */
    padding: 15px 0;
    text-align: center;
    border-radius: 3px 3px 0 0;
    color: $brand-tertiary;
    font-family: 'Usual', sans-serif;
    font-weight: 800;

    .cardHighlight & {
      border-radius: 0;
    }
  }

  .cardContent {
    flex: 1 0 auto;
    padding: 10px 0;
  }

  .cardFooter {
    padding: 10px 0 30px;
    border-radius: 0 0 3px 3px;
  }

  .cardButton {
    margin-bottom: 0;
  }

  .cardHighlightHeading {
    position: absolute;
    top: 0;
    left: 10px;
    right: 10px;
    display: block;
    padding: 5px;
    transform: translateY(-100%);
    background-color: $brand-tertiary;
    font-size: 16px;
    border-radius: 4px 4px 0 0;
    color: #fff;
    text-align: center;
  }

  .cardContentList {
    padding: 0;
    margin: 0;
    list-style: none;

    strong {
      margin-right: 5px;
      color: $brand-primary;
    }
  }

  .cardContentList li {
    display: block;
    margin-bottom: 5px;
    margin-right: -5px;
    font-size: 14px;
  }

  .cardPrice {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $brand-primary;
    padding: 0 0 40px;
    border-bottom: 1px solid #7f7f7f;

    strong {
      display: block;
      flex: none;
      font-family: 'Usual', sans-serif;
      font-size: 24px;
    }

    span {
      display: block;
      flex: none;
      padding-left: 5px;
      font-size: 14px;
    }
  }

  .cardButton {
    font-size: 14px;
    font-weight: 800;
  }

  .cardPriceWrapper {
    position: relative;
  }

  .cardPriceMonthYear {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0;
    font-size: 14px;
    color: $brand-tertiary;
  }

@include mq($from: sm) {
  .card {
    width: percentage(1/2);
  }
}

@include mq($from: md) {
  .card {
    width: percentage(1/3);
  }

    .cardContent {
      padding: 15px 0 0;
    }

      .cardContentList {
        padding: 0 0 10px;
      }

    .cardFooter {
      padding: 15px 0 30px;
    }
}

@include mq($from: lg) {
  .card {
    width: percentage(1/5);
  }

    .card:last-of-type ul li span {
      left: 0;
      transform: translate(-100%, -50%);
    }

}
