@font-face {
  font-family: 'Usual';
  src: url('./../../assets/fonts/usual-medium.woff') format('woff'),
  url('./../../assets/fonts/usual-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.mobile-header {
  padding-top: 40px;
}

#phenomic {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: #7f7f7f;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.heading,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin: 0 0 1.5rem;
}

/* Headings */
.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  font-weight: 500;
  font-family: 'Usual', sans-serif;
  color: #022e7c;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

.heading-white { /* modifier */
  color: #fff;
}

.heading-page { /* modifier */
  font-size: 32px;
}

/* Paragraphs */
p {
  line-height: 1.7;
}

.text-left {
  text-align: left;
}

.text-capitalize {
  text-transform: capitalize;
}

a {
  color: #e0032d;
}

/* IE Animation fix */
.react-bodymovin-container {
  position: relative;
  padding-bottom: 40%;
}

.react-bodymovin-container svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*button*/
.button {
  display: inline-block;
  padding: 10px 20px;
  margin: 0 20px 20px 0;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  border: 1px solid #e0032d;
  color: #e0032d;
  text-decoration: none;
  font-family: 'Usual', sans-serif;
  transition: background-color 0.25s ease, border-color 0.25s ease;
}

.button:hover,
.button:focus {
  background-color: #e0032d;
  color: #fff;
}

.buttonSpaceTop {
  margin-top: 20px;
}

.buttonCta {
  border-color: #e0032d;
  color: #fff;
  background-color: #e0032d;
}

.buttonSmall {
  font-size: 14px;
}

.ctaButton:hover,
.ctaButton:focus {
  border-color: #b30224;
  background-color: #b30224;
}

@media screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 30px;
  }

  .heading-page { /* modifier */
    font-size: 50px;
  }
}

/* Forms */
fieldset {
  padding: 0;
  border: none;
}

label {
  display: inline-block;
  margin-bottom: 0.25em;
  color: #022e7c;
  font-weight: 700;
  font-family: 'Usual', sans-serif;
}

.form fieldset + fieldset {
  margin-top: 30px;
}

.formGroup {
  margin-bottom: 20px;
  text-align: left;
}

.formGroup input,
.formGroup textarea {
  padding: 12px;
  width: 100%;
  color: #000;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #c7d2e3;
  resize: vertical;
}

.formGroup h6 {
  margin: 0 0 20px;
  font-size: 16px;
}

.form legend {
  margin-bottom: 20px;
}

.form legend,
.formText {
  text-align: left;
  overflow: hidden;
}

.form legend h2 {
  margin-bottom: 0;
}

form input[readonly] {
  background-color: #ecf4ff;
  color: #0568ff;
}

form input[readonly]::placeholder {
  color: #0568ff;
}

.FormError {
  display: block;
  margin-top: 10px;
  font-size: 12px;
  color: #8c011e;
  text-align: left;
}

.FormInput input::placeholder,
.FormInput textarea::placeholder {
  color: #bebebe;
}

.FormInput.-Stripe {
  border: 1px solid #c7d2e3;
  padding: 12px;

  &-hasError {
    box-shadow: 0 0 5px 0 #8c011e;
  }
}

.FormInput.-hasError input:focus,
.FormInput.-hasError textarea:focus {
  box-shadow: none;
}

.FormInput.-hasError input,
.FormInput.-hasError textarea {
  box-shadow: 0 0 5px 0 #8c011e;
}

.FormInlineGroup {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.FormInlineGroup .FormInput {
  flex: 1 0 auto;
  margin: 5px;
}

.FormInlineGroup button {
  margin-bottom: 0;
  align-self: center;
}

.radiogroup {
  display: flex;
}

.radiogroup label {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  width: 150px;
}

.radiogroup label:hover {
  cursor: pointer;
}

.radiogroup input {
  display: none;
}

.radiogroup span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  width: 100%;
  padding: 10px 30px;
  color: #0568ff;
  font-size: 14px;
  border: 1px solid #fff;
  border-radius: 0 3px 3px 0;
  background-color: #f2f2f2;
  text-align: center;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.radiogroup em {
  position: absolute;
  right: 10px;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  border-radius: 50%;
  font-style: normal;
  font-size: 12px;
  font-weight: normal;
  color: #0568ff;
  background-color: transparent;
  border: 1px solid #0568ff;
}

.radiogroup-transparent em {
  color: #fff;
  border-color: #fff;
}

.radiogroup input:checked + span em {
  color: #fff;
  border-color: #fff;
}

.radiogroup-transparent input:checked + span em {
  background-color: #0568ff;
}

.radiogroup-transparent span:after {
  content: '';
  display: block;
  height: 2px;
  width: 50px;
  margin: 0 auto;
  background-color: transparent;
  transform: translateY(2px);
  transition: background-color 0.2s;
}

/*.radiogroup input:checked + span:after {*/
/*background-color: #fff;*/
/*}*/

.radiogroup-transparent input:checked + span:after {
  background-color: #0568ff;
}

.radiogroup input:checked + span {
  color: #fff;
  background-color: #0568ff;
}

.radiogroup-transparent span {
  color: #fff;
  background-color: transparent;
}

.radiogroup-transparent input:checked + span {
  color: #0568ff;
  background-color: #fff;
}

.radiogroup label:first-child span {
  border-radius: 3px 0 0 3px;
}

@media screen and (min-width: 992px) {
  .FormInlineGroup .FormInput {
    flex: 1;
  }
}

/* Slick */
.slick-slider h1, .slick-slider h2, .slick-slider h3, .slick-slider h4,.slick-slider h5,.slick-slider h6{
  margin: 0 0 20px;
  font-size: 24px;
  color: #3dca88;
}

.slick-slider p {
  margin: 0 0 20px;
}

.slick-dots {
  display: flex !important; /*  slick overrides it */
  padding: 0;
  list-style: none;
}

.slick-dots button {
  display: block;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  border: none;
  background-color: #e3eceb;
  color: transparent;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.slick-dots button:active,
.slick-dots button:focus {
  outline: none;
}

.slick-dots button:hover,
.slick-dots .slick-active button {
  background-color: #3dca88;
}

.slick-dots li + li {
  margin-left: 10px;
}

.phenomic-HeadingAnchor {
  display: none;
}

.checklist {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}

.checklist li {
  display: flex;
}

.checklist-icon {
  padding-right: 10px;
}

.checklist-white { /* Modifier */
  color: #fff;
}

.checklist svg {
  width: 18px;
  height: 18px;
  fill: currentColor;
}

.checklist-blue {/* Modifier */
  svg {
    fill: #0568ff;
  }
}

@media screen and (min-width: 576px) {
  .formPostalHouseNumberGroup {
    display: flex;
    margin: 0 -10px;
  }

  .formPostalHouseNumberGroup > * {
    flex: none;
    padding: 0 10px;
  }

  .formPostalHouseNumberGroup > *:first-child {
    width: 66.66667%;
  }

  .formPostalHouseNumberGroup > *:last-child {
    width: 33.333337%
  }
}

@media screen and (min-width: 992px) {
  .formGroup {
    display: flex;
    align-items: flex-start;
  }

  .formGroupCenter {
    align-items: center;
  }

  .formGroup label {
    margin-top: 8px;
    width: 30%;
    flex: none;
  }

  .formButtonWrapper,
  .formTextWrapper {
    padding-left: 30%;
  }

  .formGroupCenter label {
    margin-top: 0;
  }

  .formGroupCenter button {
    margin: 0;
  }

  .formGroup h6 {
    flex: 0 0 auto;
    margin: 6px 20px 10px 0; /* margin-top should be the same as input padding-top */
    width: 250px;
  }

  .formGroup > div {
    flex: 1 0 auto;
    width: 70%;
  }

  .form legend {
    margin-bottom: 20px;
  }

  .radiogroup label {
    flex: 1 0 auto;
    width: auto;
  }
}

/* Modal */
.Modal {
  max-width: 1200px;
  width: 100%; /* fallback */
  width: calc(100% - 40px);
  margin: 40px auto;
  padding: 40px 20px;
  border-radius: 4px;
  background-color: #fff;
}

.Overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

small {
  font-size: 65%;
}
