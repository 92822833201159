@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.previewWrapper {
    text-align: center;

    .pageContent & {
        margin-top: 20px;
    }

    .post & {
        margin-bottom: 20px;
    }
}

.preview {
    display: flex;
    flex-wrap: wrap;
    margin: -20px;
    text-align: left;

    > * {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
    }
}

.previewFormWrapper {
    padding: 40px;
    box-shadow: 0 0 5px rgba(#c7d2e3, 0.5);
    border-radius: 4px;

    .post &,
    .pageContent & {
        padding: 20px;
    }
}

.previewWrapper .preview .previewCode {
    margin: 0;
    padding: 40px;
    flex: 1 0 auto;
    border-radius: 4px;
    background-color: $brand-primary;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
    color: #fff;
}

@include mq($from: md) {
    .preview {
        > * {
            width: 50%;
        }
    }
}
