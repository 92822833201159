.loader {
  display: flex;
  height: 25vh;
  justify-content: center;
  align-items: center;
}

:root {
  --spinner-Color: #000;
  --spinner-Color-front: color(var(--spinner-Color) a(0.8));
  --spinner-Color-back: color(var(--spinner-Color) a(0.2));
}

.spinner {
  height: 5vh;
  min-height: 5rem;
  width: 5vh;
  min-width: 5rem;
  border: 6px solid var(--spinner-Color-back);
  border-top-color: var(--spinner-Color-front);
  border-radius: 100%;
  animation: rotation 0.6s infinite linear 0.25s;

  /* the opacity is used to lazyload the spinner, see animation delay */
  opacity: 0;
}

@keyframes rotation {
  from {
    opacity: 1;
    transform: rotate(0);
  }

  to {
    opacity: 1;
    transform: rotate(359deg);
  }
}
