@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.center {
  text-align: center;
}

.enterprise {
  margin: 0 -20px;
  padding: 20px;
  box-shadow: 0 0 5px 0 rgba(#000, 0.2);
  background-color: #fff;

  @include mq($from: md) {
    padding: 20px 60px;
    margin: 0 auto;
    max-width: percentage(10/12);
  }
}

.enterpriseTitle {
  display: block;
  text-align: center;
  color: $brand-tertiary;
  font-family: 'Usual', sans-serif;
}

.enterpriseList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 40px;
  padding: 0;
  list-style: none;

  > * {
    display: flex;
    align-items: center;
    flex: none;
    width: 100%;
    padding-bottom: 5px;
    color: $brand-primary;
    font-weight: 700;

    svg {
      fill: currentColor;
      width: 1em;
      height: 1em;
      margin-right: 20px;
    }
  }

  @include mq($from: md) {
    > * {
      width: percentage(1/3);
    }
  }

  span {
    display: flex;
    align-items: center;
  }
}
