.cardTooltip {
  position: relative;
  cursor: pointer;
}

.cardTooltipBox {
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 50%;
  display: none;
  padding: 10px;
  transform: translate(-50%, 10px);
  width: 300px;
  border-radius: 3px;
  background-color: #3dca88;
  color: #fff;
  text-align: left;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.cardTooltip:hover .cardTooltipBox,
.cardTooltip:focus .cardTooltipBox {
  display: block;
  z-index: 999;
}

.cardTooltipImage {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

@media screen and (min-width: 992px) {
  .cardTooltipBox {
    left: 100%;
    top: 50%;
    transform: translate(-20px, -50%);
  }
}
