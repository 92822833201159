.prices {
  .intro {
    @include mq($from: md) {
      max-width: 50%;
      margin: 0 auto;
    }
  }

  .introLink {
    color: inherit;
    text-decoration: underline;
  }

  .hasPullUp {
    padding-bottom: 240px;
  }

  .hasPullUpSmall {
    padding-bottom: 150px;
  }

  .pullUp {
    position: relative;
    z-index: 1;
    margin-top: -300px;
  }
}
