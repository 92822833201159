@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.faqList {
  h2 {
    margin: 60px 0 40px;
  }
}

.faqListNavWrapper {
  display: flex;

  h3 {
    margin-bottom: 0;
    color: $brand-primary
  }

  nav {
    display: flex;

    a {
      display: block;
      padding: 0 10px;
      color: #e0032d;
    }
  }
}

.faqListHeading {
  display: block;
  text-align: center;
}

.faqItemsWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  > * {
    flex: none;
    width: 100%;
    padding: 0 20px;

    @include mq($from: md) {
      width: percentage(1/2);
    }
  }
}

.faqTitle {
  margin-bottom: 10px;
  color: $brand-tertiary;
}
