@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.pageContent {
  h2,
  h3,
  h4 {
    padding-top: 0;
    color: #0568ff;
  }

  h2 {
    font-size: 24px;
  }

  .pluginsTitle {
    padding-top: 2rem;
  }
}

.pageDate {
  display: block;
  margin-bottom: 20px;
  font-family: 'Usual', 'Roboto', sans-serif;
  font-size: 14px;
  color: #666e75;
}

.headerHasImage {
  position: relative;
  margin-bottom: 60px;
  padding: 150px 0 125px;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerHasImage:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(#000, 0.2);
}

.headerHasImage .pageHeader {
  position: relative;
  z-index: 2;
}

@include mq($from: lg) {
  .pageHeader {
    max-width: 80%;
    margin: 0 auto;
  }

  .pageDate {
    margin-bottom: 20px;
  }

  .pageContent {
    margin: 0 auto;
    max-width: 75%;
  }
}
