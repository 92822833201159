.intro {
  margin-bottom: 30px;
}

.vatExNote {
  margin-top: 40px;
  font-style: italic;
  text-align: center;
}

.modalTitle {
  text-align: center;
}

.iban {
  text-transform: uppercase;
}