.container {
  margin: 0 auto;
  padding: 0 20px;
}

.containerCenter {
  text-align: center;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
