@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.category {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 3px;
  border: 1px solid #8099bf;
  box-shadow: 0 0 5px 0 rgba(#000, 0.2);
  text-decoration: none;
  color: $brand-primary;
  transition: background-color 0.3s, color 0.3s;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #8099bf;
  }

  h3 {
    margin: 0;
    color: inherit;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  &.isHighlighted {
    color: #fff;
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;

    &:hover,
    &:focus {
      color: $brand-primary;
      background-color: #fff;
    }
  }
}
