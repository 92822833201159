@import '../../scss/settings/breakpoints';
@import '../../scss/settings/colors';

.header {
  position: absolute;
  z-index: 999;
  top: 0;
  width: 100%;
  padding: 10px 0;
  background-color: $brand-primary;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s ease, padding 0.25s ease;
}

.headerFixed {
  position: fixed;
}

  .headerNav {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
  }

  .headerMenuToggle {
    display: none;
  }

  .headerItems {
    position: fixed;
    background-color: $brand-primary;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    box-shadow: 3px 7px 3px 0 rgba(0, 0, 0, 0.2);
    transform: translateX(-200vh);
    transition: transform 0.25s ease, opacity 0.2s ease 0.1s;

    .headerLinkButton {
      margin-right: 0;
    }
  }

  .headerMenuToggle:checked + .headerItems {
    opacity: 1;
    transform: translateX(0);
  }

  .headerBrand {
    position: relative;
    z-index: 2;
    flex: 1 0 auto;
  }

  .headerBrandLink,
  .headerBrandLink h1 {
    display: inline-flex;
    align-items: center;
    color: #fff;
    font-family: 'Usual', 'Roboto', sans-serif;
    text-decoration: none;
  }

  .headerBrandLink img {
    width: 15px;
    max-width: 35px;
    margin-right: 20px;
    transition: max-width 0.25s ease;
  }

  .headerBrandLink h1,
  .headerBrandLink span {
    margin: 0;
    flex: 1 0 auto;
    font-size: 24px;
  }

  .headerLink {
    display: block;
    color: #fff;
    padding: 20px 10px;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Usual', sans-serif;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .headerLabelToggleIcon {
    position: relative;
    z-index: 2;
    display: block;
    width: 40px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  .headerLabelToggleIcon span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: #fff;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  .headerLabelToggleIcon span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }

  .headerLabelToggleIcon span:nth-child(odd) {
    left: 0;
    border-radius: 9px 0 0 9px;
  }

  .headerLabelToggleIcon span:nth-child(1),
  .headerLabelToggleIcon span:nth-child(2) {
    top: 0;
  }

  .headerLabelToggleIcon span:nth-child(3),
  .headerLabelToggleIcon span:nth-child(4) {
    top: 10px;
  }

  .headerLabelToggleIcon span:nth-child(5),
  .headerLabelToggleIcon span:nth-child(6) {
    top: 20px;
  }

  .headerLabelToggleIconOpen span:nth-child(1),
  .headerLabelToggleIconOpen span:nth-child(6) {
    transform: rotate(45deg);
  }

  .headerLabelToggleIconOpen span:nth-child(2),
  .headerLabelToggleIconOpen span:nth-child(5) {
    transform: rotate(-45deg);
  }

  .headerLabelToggleIconOpen span:nth-child(1) {
    left: 5px;
  }

  .headerLabelToggleIconOpen span:nth-child(2) {
    left: calc(50% - 3px);
  }

  .headerLabelToggleIconOpen span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  .headerLabelToggleIconOpen span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  .headerLabelToggleIconOpen span:nth-child(5) {
    left: 4px;
    top: 13px;
  }

  .headerLabelToggleIconOpen span:nth-child(6) {
    left: calc(50% - 3px);
    top: 13px;
  }

@include mq($from: lg) {
  .header {
    padding: 20px 0;
    box-shadow: none;
    background-color: transparent;

    &.headerStatic {
      position: relative;
      z-index: auto;
      padding-bottom: 60px;
      background: linear-gradient($brand-primary 0%, $brand-tertiary 100%);
      transition: none;
      overflow: hidden;
    }
  }

  .headerBg {
    position: absolute;
    z-index: 1;
    top: -1789px;
    left: -1310px;
    bottom: -200px;
    right: -200px;
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .headerLinkButton {
    padding: 13px !important;
    margin: 0 10px !important;

    &:last-child {
      margin-right: 0 !important;
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .headerLabelToggleIcon {
    display: none;
  }

  .headerBrandLink img {
    max-width: 60px;
    width: 60px;
  }

  .headerBrandLink h1,
  .headerBrandLink span {
    font-size: 32px;
  }

    .headerItems {
      position: static;
      width: auto;
      transform: none;
      opacity: 1;
      padding-top: 0;
      display: block;
      box-shadow: none;
      transform-origin: right;
      background-color: transparent;
    }

    .headerMenuToggle {
      display: none;
    }

    .headerLink {
      display: inline-block;
    }
}

@include mq($from: xl) {
  .headerLink {
    padding: 20px;
  }

  .headerLinkButton {
    margin: 0 20px !important;
  }
}
