@import '../../scss/settings/breakpoints';

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px 0 40px;
  margin: 0 -10px -40px;
  text-align: center;
}

.cardWrapperModal {
  padding-bottom: 0;
}

.cardWrapperLight {
  .card {
    border: 1px solid grey;
  }
}

.cardWrapperSelect {
  width: 300px;
  margin: 0 auto;
  padding-bottom: 60px;

  @include mq($from: lg) {
    width: 420px;
  }
}
