@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

$faq-line-color: mix(#7f7f7f, #fff, 50%);

.faqItem {
  h3 {
    margin: 0;
    color: $brand-primary !important;
  }

  button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;
    margin: 0;
    background: none;
    border: none;
    border-bottom: 1px solid $faq-line-color;
    text-align: left;
    color: inherit;
    font-family: inherit;
    cursor: pointer;
    outline: none;

    &:focus,
    &:active {
      box-shadow: 0 0 5px rgba($brand-tertiary, 0.5);
    }
  }
}

.faqItemAnswer {
  padding: 20px 0;
}

.faqItemIcon {
  display: inline-block;
  padding: 0 10px;
  fill: $faq-line-color;
  height: 1em;
  transition: transform 0.3s;
}

.faqItemOpen {
  .faqItemIcon {
    transform: rotateZ(180deg);
  }
}
