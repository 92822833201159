.tableContainer {
  display: block;
  width: 100%;
  overflow-x: auto;

  &--sdks table {
    img {
      max-height: 40px;
      max-width: none;
    }
  }
}

.table {
  width: 100%;
  margin: 1rem 0;
  border-collapse: collapse;
  text-align: left;

  td,
  th {
    padding: .75rem;
    border-top: 1px solid #eceeef;
    text-align: left;
  }

  th {
   border-bottom: 2px solid #eceeef;
  }

  img {
    max-width: 120px;
    height: auto;
  }
}
