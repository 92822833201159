.alert {
  display: block;
  text-decoration: none;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid lighten(#8c011e, 20%);
  color: #8c011e;
  background: lighten(#8c011e, 60%);
  transition: background 0.2s;

  &:hover,
  &:focus {
    background: lighten(#8c011e, 50%);
  }
}
