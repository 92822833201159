@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.toggler {
  position: relative;
  width: 80px;
  height: 35px;
}

.togglerCenter { // modifier
  text-align: center;
  position: relative;
  margin: 0 auto;

  .togglerLabel {
    position: absolute;
    top: -8px;
  }
  .togglerLabel:first-of-type {
    left: -20px;
    transform: translateX(-100%);
  }

  .togglerLabel:last-of-type {
    right: -20px;
    width: 90px;
    transform: translateX(100%);
  }
}

.togglerInversed {
  .togglerLabel {
    color: #fff;
  }

  .toggle {
    background-color: #fff;
  }

  .toggleSwitch {
    background-color: $brand-primary;
  }
}

.toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: $brand-primary;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.toggleInput {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;

  &:checked ~ .toggleSwitch {
    right: 57.5%;
    left: 2px;
    transition-delay: 0s, .08s;
  }
}

.toggleSwitch {
  position: absolute;
  z-index: 1;
  right: 2px;
  left: 57.5%;
  top: 2px;
  bottom: 2px;
  display: block;
  background-color: #fff;
  border-radius: 36px;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0.08s, 0s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}


.togglerLabel {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  color: $brand-primary;
  transition: .2s;
  font-weight: bold;
}
