.support {
  .pageContent {
    h2,
    h3,
    h4 {
      padding-top: 0;
      color: #0568ff;
    }

    h2 {
      font-size: 24px;
    }
  }

  .intro {
    @include mq($from: md) {
      max-width: 50%;
      margin: 0 auto;
    }
  }

  .hasPullUp {
    padding-bottom: 300px;
  }

  .hasPullUpSmall {
    padding-bottom: 150px;
  }

  .pullUp {
    position: relative;
    z-index: 1;
    margin-top: -300px;
  }

  @include mq($from: lg) {
    .pageContent {
      margin: 0 auto;
      max-width: 75%;
    }
  }
}
