.uspWrapper {
  display: block;
  padding-bottom: 40px;
}

  .usp {
    margin-bottom: 60px;
  }

  .usp:last-of-type {
    margin-bottom: 0;
  }

  .uspHeading {
    margin-bottom: 10px;
    font-size: 20px;
    color: $brand-tertiary;
  }

  .uspDescription {
    margin: 0;
  }

  .uspImage {
    height: 60px;
  }

  .customerImageHolder {
    display: none;
  }

  .customerImage {
    height: 100%;
  }

.introWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
}

  .introGif {
    display: block;
    margin: 0 auto 40px;
    width: 70%;
    text-align: center;
  }

  .introColumn {
    flex: 0 0 auto;
    padding: 0 40px;
    width: 100%;
  }

  .introBtnWrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

.vatExNote {
  margin-top: 40px;
  font-style: italic;
}

.implementationImg {
  display: none;
}

@include mq($from: sm) {
  .introGif {
    max-width: 50%;
  }

  .implementationImg {
    display: block;
    margin: 0 auto 40px;
    padding-bottom: 30%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('/assets/easy-to-implement.svg');
  }
}

@include mq($from: lg) {
  .introColumn {
    width: 50%;
    padding: 0 20px;
  }

  .introGif {
    max-width: 70%;
  }

  .introBtnWrapper {
    margin-bottom: 0;
  }

  .usps {
    display: flex;
    margin: 0 -20px -40px;
    padding-top: 10px;
    flex-wrap: wrap;

    > * {
      flex: none;
      width: 50%;
      padding: 0 20px 40px;
    }
  }

  .usp {
    display: flex;
    align-items: flex-start;
  }

  .uspDescription {
    max-width: 100%;
  }

  .uspImageHolder {
    flex: none;
    width: 20%;
  }

  .uspTextContent {
    text-align: left;
  }

  .uspsTextWrapper {
    p {
      max-width: 80%;
    }
  }

  .customerText {
    width: 40%;
  }

  .customerImageHolder {
    display: block;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 50%;
    bottom: -80px;
    right: 0;
    transform: translate(0, -40px);
  }
}
