.section {
  position: relative;
  padding: 60px 0;
  background-color: #fff;

  &.sectionSkew:before {
    background-color: #fff;
  }
}

.sectionSkew {
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    height: 70px;
    left: -20px;
    top: -35px;
    right: -20px;
  }

  &.sectionSkewLeft:before {
    transform: rotate(1.5deg);
  }

  &.sectionSkewRight:before {
    transform: rotate(-1.5deg);
  }
}

@each $colorName, $color in $colors {
  .section#{$colorName} {
    background-color: $color;

    &.sectionSkew:before {
      background-color: $color;
    }

    &.sectionSkewRight {
      margin-top: 35px;
    }
  }
}

  .sectionDescription {
    margin-left: auto;
    margin-right: auto;
  }

  .sectionDescriptionCentered {
    display: flex;
    justify-content: center;
  }

.sectionBrand { /* modifier */
  padding: 100px 0;
  color: #fff;
  background: linear-gradient($brand-primary 0%, $brand-tertiary 100%);
}

.sectionBg {
  position: absolute;
  z-index: 1;
  top: -1789px;
  left: -1310px;
  bottom: -200px;
  right: -200px;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}

.sectionBrandInvert { /* modifier */
  padding: 100px 0;
  color: #fff;
  background: linear-gradient(360deg, $brand-primary 0%, $brand-tertiary 80%);

  &.sectionSkew:before {
    background-color: $brand-tertiary;
  }
}

.sectionBgWrapper {
  overflow: hidden;
}

.sectionBrand,
.sectionBrandInvert {
  position: relative;

  .sectionContent {
    position: relative;
    z-index: 2;
  }
}

.sectionLarge { /* modifier */
  padding: 100px 0 120px;
}

.sectionCenter { /* modifier */
  text-align: center;
}

.sectionFlatten { /* modifier */
  padding: 0;
}

@include mq($from: md) {
  .sectionDescription {
    max-width: 90%;
  }

  .section h1 {
    font-size: 30px;
  }

  .sectionFlatten { /* modifier */
    padding: 0;
  }
}

@include mq($from: lg) {
  .section {
    padding: 80px 0 110px;
  }

  .sectionBrand {
    padding: 150px 0 100px;
  }

  .sectionFlatten { /* modifier */
    padding: 0;
  }

    .sectionDescription {
      max-width: 60%;
    }

  .sectionCustomers { /* modifier */
    padding: 180px 0 140px;
  }
}
