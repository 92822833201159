@import '../../scss/settings/colors';
@import '../../scss/settings/breakpoints';

.customerReviews {
  margin: 50px -20px -40px;
}

.customerReview {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px;
}

.customerReviewContent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 80px 20px 40px;
  border: 1px solid $brand-secondary;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(#000, 0.2);
  background-color: #fff;

  p {
    flex: 1 0 auto;
  }

  span {
    display: block;
    font-size: 80%;
  }
}

.customerReviewImgContainer {
  position: absolute;
  top: -50px;
  left: 50%;
  margin-left: -50px;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  overflow: hidden;
  border: 2px solid #fff;
}

.customerReviewName {
  margin: 0;
  color: $brand-tertiary;
}

@include mq($from: md) {
  .customerReviews {
    display: flex;
    flex-wrap: wrap;
  }

  .customerReview {
    width: percentage(1/3);
    flex: none;
  }
}
