.post {
  .header {
    display: flex;
    justify-content: center;
    opacity: 0.6;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  .sdk-img {
    padding: 0 0 2rem;

    img {
      max-height: 100px;
    }
  }
  .sdk-preview {
    margin-top: 4rem;
    .preview {
      margin-bottom: 2rem;
    }
  }
}
