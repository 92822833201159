@import '../../scss/settings/breakpoints';
@import '../../scss/settings/colors';

$footer-color: #68A3FE;

.footer {
  position: relative;
  font-size: 16px;
  background-color: $brand-secondary;
}

  .footerContent {
    position: relative;
    z-index: 2;
    padding: 40px 0 20px;
  }

  .footerWrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    color: $footer-color;
    margin: 0 -20px;

    > * {
      padding: 0 20px 20px;
      width: 100%;
    }
  }

  .footerLogo {
    .footerLogoWrapper {
      width: 100%;
      max-width: 35px;
    }
  }

  .footerSocial {
    img {
      width: 40px;

    }
  }

  .footerSocial:last-child {
    margin-right: 0;
  }

  .footerLink {
    color: $footer-color;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $brand-primary;
      text-decoration: underline;
    }
  }

  .footerTradeName {
    margin: 0;
    color: $footer-color;
    font-size: 14px;
  }

  .footerPoweredBy .footerPoweredByContainer {
    max-width: 100px;
  }

  .footerColumnTitle {
    margin: 0 0 10px;
    font-size: 20px;
    color: $brand-tertiary;
  }

  .footerLogo {
    width: 100%;
    padding-right: 10px;
  }

  .footerBrands {
    display: flex;
    align-items: center;
    margin: 0 -10px;

    > * {
      padding: 0 10px;
    }
  }

@include mq($from: md) {
  .footerColumn {
    flex: none;
    width: percentage(1/2);
  }
}

@include mq($from: lg) {
  .footerColumn {
    flex: none;
    width: percentage(1/4);
  }

  .footerColumnLogo {
    position: relative;
  }

  .footerLogo {
    position: absolute;
    top: -10px;
    left: 10px;
    width: 35px;
    height: 44px;
    padding-right: 0;
    transform: translateX(-100%);
  }

  .footerPoweredBy .footerPoweredByContainer {
    max-width: 150px;
  }

  .footerTradeName {
    margin-left: 50px;
  }
}
